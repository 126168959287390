import React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Partenaires from "components/Partenaires";

/* Markdown Converter */
const showdown = require("showdown");
const converter = new showdown.Converter();

const OtherPages = ({ pageContext: content, location }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _partners,
    _staticTexts,
    _topMenus,
    _companyMenus,
    _lang,
    _blogs,
  } = content;

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      menuSection={content.menuSection}
      menuTitle={content.menuTitle}
      slug={content.slug}
      ratingValue={content.ratingValue || 4.9}
      ratingCount={content.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{content.pageTitle} | Losali Direct</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={content.metaKeywords} />
        <meta name="description" content={content.metaDescription} />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="page2-box1 card-primary" style={{ width: "100%" }}>
          <div className="other-card page2-card-left">
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(content.body),
              }}
            />
          </div>
        </div>
      </main>

      <Partenaires partners={_partners} />
    </Layout>
  );
};

export default OtherPages;
